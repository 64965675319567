import { faCopy, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft, faArrowRight, faArrowRotateLeft, faArrowRotateRight, faEdit, faPaste, faRotate, faScissors } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const rightClickData = [
    {
        id: 1,
        title: localStorage.getItem("language") === "en" ? "Copy" : "Copiază",
        icon: <FontAwesomeIcon icon={faCopy} className="right-menu-icon" />
    },

    {
        id: 2,
        title: localStorage.getItem("language") === "en" ? "Paste" : "Lipește",
        icon: <FontAwesomeIcon icon={faPaste} className="right-menu-icon" />
    },

    {
        id: 3,
        title: localStorage.getItem("language") === "en" ? "Cut" : "Decupare",
        icon: <FontAwesomeIcon icon={faScissors} className="right-menu-icon" />
    },
    

    {
        id: 4,
        title: localStorage.getItem("language") === "en" ? "Delete" : "Ștergere",
        icon: <FontAwesomeIcon icon={faTrashCan} className="right-menu-icon" />,
        danger: true
    },

    {
        id: 5,
        title: localStorage.getItem("language") === "en" ? "Rotate" : "Rotire",
        icon: <FontAwesomeIcon icon={faArrowRotateLeft} className="right-menu-icon rotate-left" />,
        icon2: <FontAwesomeIcon icon={faArrowRotateRight} className="right-menu-icon rotate-right" />,
    },

    {
        id: 6,
        title: localStorage.getItem("language") === "en" ? "Edit name" : "Editare nume",
        icon: <FontAwesomeIcon icon={faEdit} className="right-menu-icon" />
    }
];

export default rightClickData;