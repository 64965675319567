import { faCircleExclamation, faDoorClosed, faDoorOpen, faFlagCheckered, faListOl } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck, faCircleXmark, faStar, faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import "./LeaderboardDebugSession.css";

const LeaderboardDebugSession = ({ data, socket }  : { data : any, socket: any }) => {

    const params = useParams();
    const sessionId = params?.sessionId;
    console.log(sessionId);
    const [results, setResults] = useState<Array<any>>([]);
    const [cookies, setCookie] = useCookies();
    const navigate = useNavigate();

    const [title, setTitle] = useState<string | null>(null);
    const [sessionFinished, setSessionFinished] = useState<boolean>(false);
    const [schemeNumber, setSchemeNumber] = useState<number>(0);
    
    const getLeaderboard = async () => {

        const options = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/get-results-of-session/${data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId}/${sessionId}`, options);
            const response = await request.json();
            console.warn(response);
            const results = response.results;
            setResults(results);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        (async () => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
             
            };

            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/check-id/${data.userData.type === "teacher" ? data.userData.userId : data.userData.teacherId}/${sessionId}`, options);
                const response = await request.json();
                if (!response.success) return navigate(-1);
                console.warn(response.results);

                setTitle(response.results?.name);
                setSessionFinished(response.results.finished)
                setSchemeNumber(Number(response?.results?.number));
                await getLeaderboard();
            } catch (e) {
                console.log(e);
            }
        })(); 

        
    }, []);


    useEffect(() => {
        socket.on("update-results", async (currResults : any) => {
            const options = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            try {
                const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/get-results-of-session/${data.userData.userId}/${sessionId}`, options);
                const response = await request.json();

                console.warn(response.results);

                if (response.success) setResults(response.results);

            } catch (e) {
                console.log(e);
            }
        });
    }, []);


    const finishSessionTeacher = async () => {
        if (data.userData.type !== "teacher") return;
        
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                teacherId: data.userData.userId,
                sessionId
            })
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/session/finish-session`, options);
            const response = await request.json();
            if (response.success) {
                socket.emit("session-finished", sessionId, data.userData.userId);
                window.location.reload();
            }
            
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="content">
            <Header data={data} socket={socket} />

            {title ? <h2 className="title">{title}</h2> : null}

            <div className="session-containers">
                {data.userData.type === "teacher" ? <div className="join-session-container" onClick={() => window.location.replace(`/session/${sessionId}/${schemeNumber}/1`)}>
                    <FontAwesomeIcon icon={faDoorOpen} className="icon"/>
                    <h2>{cookies?.language === "en" ? "Join session" : "Intra in sesiune"}</h2>
                </div> : null}

                {data.userData.type === "teacher" && !sessionFinished ? <div className="finish-session-container" onClick={async () => await finishSessionTeacher()}>
                    <FontAwesomeIcon icon={faFlagCheckered} className="icon"/>
                    <h2>{cookies?.language === "en" ? "Finish session" : "Incheie sesiunea"}</h2>
                </div> : null}
            </div>
    

            <div className="leaderboard">

                <div className="row">

                    <div className="column">
                        <FontAwesomeIcon icon={faListOl} className="column-icon" />
                        <p>{cookies?.language === "en" ? "No." : "Nr."}</p>
                    </div>

                    <div>
                        <FontAwesomeIcon icon={faUser} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Name" : "Nume"}</p>
                    </div>

                    <div>
                        <FontAwesomeIcon icon={faDoorOpen} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Grade" : "Clasa"}</p>
                    </div>

                    <div className="column">
                        <FontAwesomeIcon icon={faCircleExclamation} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Defects" : "Defecte"}</p>
                    </div>

                    <div className="column">
                        <FontAwesomeIcon icon={faCircleCheck} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Correct" : "Corecte"}</p>
                    </div>

                    <div className="column">
                        <FontAwesomeIcon icon={faCircleXmark} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Wrong" : "Gresite"}</p>
                    </div>

                    <div className="column">
                        <FontAwesomeIcon icon={faStar} className="column-icon" />
                        <p>{cookies?.language === "en" ? "Points" : "Puncte"}</p>
                    </div>

                </div>

                {results && results.length ? results.map((result, idx) => (
                    <div className="user" key={idx}>
                        <div className="column">
                            <p>{idx + 1}</p>
                        </div>

                        <div className="column">
                            <p>{result.name}</p>
                        </div>

                        <div className="column">
                            <p>{result?.grade || (cookies?.language === "en" ? "Undefined" : "Nedefinit")}</p>
                        </div>

                        <div className="column">
                            <p>{result.problemsSolved.length} / {result.problems.length} </p>
                        </div>

                        <div className="column">
                            <p>{result?.correct?.length || 0}</p>
                        </div>

                        <div className="column">
                            <p>{result?.wrong?.length || 0}</p>
                        </div>

                        <div className="column"  >
                            <p>{result.points || 0} / 100</p>
                        </div>
                    </div>
                )) : null}
            </div>

            {/* {data.userData.type === "teacher" && !sessionFinished ? (
                <div className="start-session-button" onClick={async () => await finishSessionTeacher()}>
                    <p>{cookies?.langauge === "en" ? "Finish session" : "Incheie sesiunea"}</p>
                </div>
            ) : null} */}

           
        </div>
    );
};

export default LeaderboardDebugSession;