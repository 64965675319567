import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import "./Header.css";

const Header = ({ data, socket } : { data: any, socket: any }) => {

    const navigate = useNavigate();
    const [count, setCount] = useState<number>(0);
    const [cookies, setCookie, removeCookie] = useCookies();

    useEffect(() => {
        socket.on("update-sessions", (sessions : any) => {
            setCount(currCount => currCount + 0.5);
        });
    }, []);

    const removeCookies = () => {
        Object.keys(cookies).forEach((cookie : string) => removeCookie(cookie));
        window.location.replace("/login");
    };

    return (
        <header>

            <div className="element">
                <p onClick={() => window.location.replace("/categories")}>{cookies?.language === "en" ? "Home" : "Acasa"}</p>
                <div className="line"></div>
            </div>

            {data.userData.type === "teacher" ? <div className="element">
                <p onClick={() => window.location.replace("/students")}>{cookies?.language === "en" ? "Students" : "Elevi"}</p>
                <div className="line"></div>
            </div> : null}

            {data.userData?.admin ? <div className="element">
                 <p onClick={() => window.location.replace("/users")}>{cookies?.language === "en" ? "Users" : "Utilizatori"}</p>
                <div className="line"></div>
            </div> : null}

            <div className="element">
                 <p onClick={() => window.location.replace("/profile")}>{cookies?.language === "en" ? "Profile" : "Profil"}</p>
                <div className="line"></div>
            </div>

          

            <div className="element center-element">
                <p>ZenVolt v1.2.5</p>
                <div className="line"></div>
            </div>

            <div className="element element-pushed">
                <p onClick={() => removeCookies()}>{cookies?.langauge === "en" ? "Log out" : "Delogare"}</p>
                <div className="line"></div>
            </div>
        </header>
    );
};

export default Header;