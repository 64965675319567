import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import "./Grid.css";
import SCHEME_ELEMENTS from "../../Functions/getElements";

import { useLocation, useParams } from "react-router-dom";

const buzzerSound = require("../../sounds/buzzer.mp3");
const screwSound = require("../../sounds/screw.mp3");

const CATEGORIES = ["contacts", "conductors", "bobines", "buttons", "engines", "others"];

// console.log = () => {};

const GridElement = ({ problems, useStore, page, rowIdx, idx, otherProps, isSelectedCell, COLUMNS, ROWS, cellRef, setSingleElementDrag, changeIsSelectedSign, removeSelectedSign, dragElement, initiateElement, moveElement, stopElement, touchInitiateElement, touchMoveElement, category, findSign, findSignObject, removeSign, addSign, copiedElement, cuttedElement, isImageLocked, changeSelectedCell, hasCurrent, getElectricity, electricityLine, multipleSelectedCells, setMultipleSelectedCells } : any) => {
    
    // const signs = useStore((state: any) => state.elements);

    const replaceSign = useStore((state: any) => state.replaceSign);
    const params = useParams();
    const schemeNumber = useMemo(() => params?.schemeNumber, [params]);

    const signs = useStore((state: any) => category === "0" ? state.elements.filter((element : any) => element.page === page).map((element : any) => {
        const { flip, name, signColor, x, y, texts } = element;
        return {
            flip,
            name,
            signColor,
            x,
            y,
            texts
    
        };
    }) : state.elements.filter((element : any) => element.page === page));



    const schemeStructure = useStore((state: any) => state.schemeStructure);
    const initialSchemeStructure = useMemo(() => schemeStructure, []);
    

    const currentFoundSign = useMemo(() => findSign(idx, rowIdx), [signs]);
    const foundSignObject = useMemo(() => findSignObject(idx, rowIdx), [signs])
    let startDrag = useMemo(() => false, []);

    useEffect(() => {
        console.log(currentFoundSign);
    }, [currentFoundSign]);

    const findSignObjectByName = useCallback((name : string) => {
        return signs.find((sign : any) => sign.texts.some((text: { name: string }) => text.name === name));
    }, [signs]);

    const CaQ1 = useMemo(() => findSignObjectByName("C.a. Q1"), [signs]);
    const CaQ2 = useMemo(() => findSignObjectByName("C.a. Q11.2"), [signs]);
    const S4 = useMemo(() => findSignObjectByName("F4"), [signs]);
    const S5 = useMemo(() => findSignObjectByName("F5"), [signs]);
    const currentRT = useMemo(() => findSignObjectByName(Number(schemeNumber) === 2 ? "R.T." : Number(schemeNumber) === 3 ? "F11.1" : 'F11.1'), [signs, schemeNumber]);
                
    const nullList = useMemo(() => Number(schemeNumber) === 2 ? [20, 21, 38, 39, 40, 41, 77, 76] : Number(schemeNumber) === 3 ? [] : [], [schemeNumber]);
    const nullProblem = useMemo(() => problems.some((element: { point: number, type: string | null }) => nullList.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved), [schemeStructure, problems]);

    useEffect(() => {
        if (category === "0") return;
        if (Number(schemeNumber) === 1) return;

        const intreruptor1 = findSignObjectByName(Number(schemeNumber) < 4 ? "Q1" : "Q11.1");
        const intreruptor2 = findSignObject(intreruptor1?.x + 1, intreruptor1?.y);
        const intreruptor3 = findSignObject(intreruptor1?.x + 2, intreruptor1?.y);

        const Q2intreruptor1 = findSignObjectByName("Q11.2");
        const Q2intreruptor2 = findSignObject(Q2intreruptor1?.x + 1, Q2intreruptor1?.y);
        const Q2intreruptor3 = findSignObject(Q2intreruptor1?.x + 2, Q2intreruptor1?.y);

        const Q3intreruptor1 = findSignObjectByName("Q11.3");
        const Q3intreruptor2 = findSignObject(Q3intreruptor1?.x + 1, Q3intreruptor1?.y);
        const Q3intreruptor3 = findSignObject(Q3intreruptor1?.x + 2, Q3intreruptor1?.y);

        const CiQ2 = findSignObjectByName("C.i Q11.2");
        const CiQ1 = findSignObjectByName("C.i Q11.1");
        const T1Q2 = findSignObjectByName("T1.Q2");
        const T1Q3 = findSignObjectByName("T1.Q3");

        const TCiQ2 = findSignObjectByName("T1 C.i. Q11.2");
        const TCiQ3 = findSignObjectByName("T1 C.i. Q11.3");

 
        if (CaQ1?.names?.length && CaQ1.names.some((name : { name: string }) => name.name.includes("deschis"))) {
            actuationAlgorithm(intreruptor1?.x, intreruptor1?.y, true);
            actuationAlgorithm(intreruptor2?.x, intreruptor2?.y, true);
            actuationAlgorithm(intreruptor3?.x, intreruptor3?.y, true);


            // if (Number(schemeNumber) === 4) {
            //     realActuationAlgorithm(Q3intreruptor1?.x, Q3intreruptor1?.y, true);
            //     realActuationAlgorithm(Q3intreruptor2?.x, Q3intreruptor2?.y, true);
            //     realActuationAlgorithm(Q3intreruptor3?.x, Q3intreruptor3?.y, true);

            //     realActuationAlgorithm(Q2intreruptor1?.x, Q2intreruptor1?.y, true);
            //     realActuationAlgorithm(Q2intreruptor2?.x, Q2intreruptor2?.y, true);
            //     realActuationAlgorithm(Q2intreruptor3?.x, Q2intreruptor3?.y, true);

            //     realActuationAlgorithm(T1Q2?.x, T1Q2?.y, false);
            //     realActuationAlgorithm(T1Q3?.x, T1Q3?.y, true);
            //     realActuationAlgorithm(CiQ2?.x, CiQ2?.y, true);
            //     realActuationAlgorithm(CiQ1?.x, CiQ1?.y, true);
            //     realActuationAlgorithm(TCiQ2?.x, TCiQ2?.y, false);
            //     realActuationAlgorithm(TCiQ3?.x, TCiQ3?.y, false);
                
            // }
            
        } else if (S4?.names?.length && currentRT?.names?.length && (!S4.names.some(( name: { name: string } ) => name.name.includes("deschis")) || nullProblem) && (S5?.names?.length ? !S5.names.some((name : { name: string }) => name.name.includes("deschis")) : true) && !currentRT?.names?.some(( name: { name: string } ) => name.name.includes("deschis"))) {
            if (Number(schemeNumber) !== 4) {
                actuationAlgorithm(intreruptor1?.x, intreruptor1?.y, false);
                actuationAlgorithm(intreruptor2?.x, intreruptor2?.y, false);
                actuationAlgorithm(intreruptor3?.x, intreruptor3?.y, false);
            }
        
            return () => {
                // clearTimeout(longTimer);
            };
        }
    
    }, [CaQ1]);

    useEffect(() => {
        if (category === "0") return;
        if (Number(schemeNumber) === 1 || Number(schemeNumber) === 2 || !CaQ2) return;
        const intreruptor1 = findSignObjectByName("Q11.2");
        const intreruptor2 = findSignObject(intreruptor1?.x + 1, intreruptor1?.y);
        const intreruptor3 = findSignObject(intreruptor1?.x + 2, intreruptor1?.y);
        if (CaQ2?.names?.length && CaQ2.names.some((name : { name: string }) => name.name.includes("deschis"))) {
            console.log("2", CaQ2);
            actuationAlgorithm(intreruptor1?.x, intreruptor1?.y, true);
            actuationAlgorithm(intreruptor2?.x, intreruptor2?.y, true);
            actuationAlgorithm(intreruptor3?.x, intreruptor3?.y, true);

        } else if (S4?.names?.length && currentRT?.names?.length && (!S4?.names?.some((name : { name : string }) => name?.name?.includes("deschis")) || nullProblem)  && (S5?.names?.length ? !S5?.names?.some((name : { name : string }) => name.name.includes("deschis")) : true) && !currentRT?.names?.some((name : { name: string }) => name.name.includes("deschis"))) {
            actuationAlgorithm(intreruptor1?.x, intreruptor1?.y, false);
            actuationAlgorithm(intreruptor2?.x, intreruptor2?.y, false);
            actuationAlgorithm(intreruptor3?.x, intreruptor3?.y, false);
        }
    }, [CaQ2]);

    const T1Q2 = useMemo(() => findSignObjectByName("T1.Q2"), [findSignObjectByName("T1.Q2"), findSignObjectByName("T1.Q3")]);
    const T1Q3 = useMemo(() => findSignObjectByName("T1.Q3"), [findSignObjectByName("T1.Q3"), findSignObjectByName("T1.Q2")]);

    const replaceAll = (str : string, find : string, replace : string) => {
        return str.split(find).join(replace);
    }
    
    
    const actuationAlgorithm = (currIdx : number, currRowIdx : number, open : boolean | null = null, callback : boolean | null = true) => {
        if (category === "0") return;

        const currSignObj: any = findSignObject(currIdx, currRowIdx);
        const currSign: any = findSign(currIdx, currRowIdx);
        
        if (!currSign) return;
        const source = currSign?.props?.children?.[0]?.[0]?.props?.src;
        console.log(currSign?.props, currSign?.props?.children?.[0], currSign?.props?.children?.[0]?.[0]?.props?.src)
        if (!(source.includes("/siguranta") && !source.includes("siguranta_automata")) && !(source.includes("/contact_normal_deschis") || source.includes("/contact_normal_inchis"))) return;

        if (source.includes("/siguranta") && !source.includes("siguranta_automata")) {
            const isOpen = source.includes("/siguranta_deschisa");
            removeSign(currIdx, currRowIdx, page);
            if (isOpen && (open !== null ? open : true)) addSign(currIdx, currRowIdx, page, SCHEME_ELEMENTS["siguranta"].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
            else addSign(currIdx, currRowIdx, page, SCHEME_ELEMENTS["siguranta_deschisa"].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");

            console.log(currSignObj);

        } else if (source.includes("actionare_prin_apasare") && Number(schemeNumber) !== 1) {
            const isOpen = source.includes("/contact_normal_deschis");
            const newSource = isOpen && (open !== null ? open : true) ? replaceAll(source, "deschis", "inchis").slice(14) : replaceAll(source, "inchis", "deschis").slice(14);
            const realNewSource = newSource.split(".")[0];

            removeSign(currIdx, currRowIdx, page);
            addSign(currIdx, currRowIdx, page, SCHEME_ELEMENTS[realNewSource].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
            const selfMaintananceQ1 = findSignObjectByName("C.a. Q1");
            const selfMaintananceQ2 = findSignObjectByName("C.a. Q11.2");
            const interlockQ1 = findSignObjectByName("C.i. Q11.1");
            const interlockQ2 = findSignObjectByName("C.i. Q11.2");
            const BP1 = findSignObjectByName(Number(schemeNumber) === 2 ? "B.p." : "B.p.");

            // const selfMaintananceQ11 = findSignObjectByName("Q11.1.");
            // const selfMaintananceQ12 = findSignObjectByName("Q11.2.");


            // const selfMaintananceQ1 = findSignObject(selfMaintananceQ1Obj?.x || (currIdx + 1), selfMaintananceQ1Obj?.y || currRowIdx);

            setTimeout(() => {
                if (Number(schemeNumber) === 1) return;
                const nullList = Number(schemeNumber) === 2 ? [20, 21, 38, 39, 40, 41, 77, 76] : [];
                const fifthTotalLinePoints = Number(schemeNumber) === 2 ? [22, 23, 24, 25, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 64, 65, 66, 67, 74, 75] : selfMaintananceQ1?.names?.some((name : { name: string }) => name.name.includes("inchis")) ? [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 54, 55, 58, 62, 60, 61, 63, 59, 56, 57, 74, 75, 76, 77, 94, 95] : selfMaintananceQ2?.names?.some((name : { name : string }) => name.name.includes("inchis")) ? [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 64, 65, 68, 72, 70, 71, 73, 69, 66, 67, 78, 79, 80, 81, 98, 99] : [];
                const fourthTotalLinePoints = Number(schemeNumber) === 2 ? schemeStructure.filter((element: { line: string }) => element.line.includes("Nul") || element.line.includes("N")).map((elem : any) => elem.dotNumber) : selfMaintananceQ1?.names?.some((name : { name: string }) => name.name.includes("inchis")) ? [96, 97] : [100, 101];

                const nullProblem = problems.some((element: { point: number, type: string | null }) => nullList.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved);
                const fifthLineProblem = problems.some((element: { point: number, type: string | null }) => element.type !== "Scurt circuit" && fifthTotalLinePoints.includes(element.point) && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved);
                const fourthLineProblem = problems.some((element: { point: number, type: string | null }) => element.type !== "Scurt circuit" && fourthTotalLinePoints.includes(element.point) && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved);

                if ((currSignObj.names.some((name : { name: string }) => name.name.includes("automentinere")) && !selfMaintananceQ1.names.some((name : { name : string }) => name.name.includes("inchis")) && !fifthLineProblem && !fourthLineProblem && (Number(schemeNumber) === 2 || currSignObj.texts.some((text: { name: string }) => text.name === "S1"))) || (currSignObj.texts.some((text: { name: string }) => text.name.includes("B.o.")) && selfMaintananceQ1.names.some((name : { name: string }) => name.name.includes("inchis")))) {
                    console.log(interlockQ2);
                    if (interlockQ2?.x == null || interlockQ2?.names?.some((name : { name: string }) => name.name.includes("inchis"))) {
                        if (!selfMaintananceQ1?.names.some((name : { name: string }) => name.name.includes("deschis")) || ((!S4?.names?.some((name : { name: string }) => name.name.includes("deschis")) || nullProblem) && !S5?.names?.some((name : { name: string }) => name.name.includes("deschis")) && !currentRT?.names?.some((name : { name: string }) => name.name.includes("deschis")))) {
                            //console.log("Close");
                            actuationAlgorithm(selfMaintananceQ1?.x || (currIdx + 1), selfMaintananceQ1?.y || currRowIdx, currSignObj.texts.some((text: { name: string }) => text.name === "B.p.") ? false : true);
                            
                            if (Number(schemeNumber) === 4 && currSignObj.texts.some((text: { name: string }) => text.name === "B.o.") ) {
                                
                                const intreruptor1 = findSignObjectByName(Number(schemeNumber) < 4 ? "Q1" : "Q11.1");
                                const intreruptor2 = findSignObject(intreruptor1?.x + 1, intreruptor1?.y);
                                const intreruptor3 = findSignObject(intreruptor1?.x + 2, intreruptor1?.y);
                        
                                const Q2intreruptor1 = findSignObjectByName("Q11.2");
                                const Q2intreruptor2 = findSignObject(Q2intreruptor1?.x + 1, Q2intreruptor1?.y);
                                const Q2intreruptor3 = findSignObject(Q2intreruptor1?.x + 2, Q2intreruptor1?.y);
                        
                                const Q3intreruptor1 = findSignObjectByName("Q11.3");
                                const Q3intreruptor2 = findSignObject(Q3intreruptor1?.x + 1, Q3intreruptor1?.y);
                                const Q3intreruptor3 = findSignObject(Q3intreruptor1?.x + 2, Q3intreruptor1?.y);
                        
                                const CiQ2 = findSignObjectByName("C.i Q11.2");
                                const CiQ1 = findSignObjectByName("C.i Q11.1");
                                const T1Q2 = findSignObjectByName("T1.Q2");
                                const T1Q3 = findSignObjectByName("T1.Q3");
                        
                                const TCiQ2 = findSignObjectByName("T1 C.i. Q11.2");
                                const TCiQ3 = findSignObjectByName("T1 C.i. Q11.3");
                                
                                actuationAlgorithm(intreruptor1?.x, intreruptor1?.y, true);
                                actuationAlgorithm(intreruptor2?.x, intreruptor2?.y, true);
                                actuationAlgorithm(intreruptor3?.x, intreruptor3?.y, true);
                    
                                if (Number(schemeNumber) === 4) {
                                    realActuationAlgorithm(Q3intreruptor1?.x, Q3intreruptor1?.y, true);
                                    realActuationAlgorithm(Q3intreruptor2?.x, Q3intreruptor2?.y, true);
                                    realActuationAlgorithm(Q3intreruptor3?.x, Q3intreruptor3?.y, true);
                    
                                    realActuationAlgorithm(Q2intreruptor1?.x, Q2intreruptor1?.y, true);
                                    realActuationAlgorithm(Q2intreruptor2?.x, Q2intreruptor2?.y, true);
                                    realActuationAlgorithm(Q2intreruptor3?.x, Q2intreruptor3?.y, true);
                    
                                    realActuationAlgorithm(T1Q2?.x, T1Q2?.y, false);
                                    realActuationAlgorithm(T1Q3?.x, T1Q3?.y, true);
                                    realActuationAlgorithm(CiQ2?.x, CiQ2?.y, true);
                                    realActuationAlgorithm(CiQ1?.x, CiQ1?.y, true);
                                    realActuationAlgorithm(TCiQ2?.x, TCiQ2?.y, false);
                                    realActuationAlgorithm(TCiQ3?.x, TCiQ3?.y, false);
                                    
                                }
                            }

                            
                            
                            // if (callback) setTimeout(() => actuationAlgorithm(BP1?.x || 0, BP1?.y || 0, false, false), 2000);
                            if (interlockQ1?.x !== null) {
                                actuationAlgorithm(interlockQ1?.x || (currIdx + 1), interlockQ1?.y || currRowIdx);
                            }
                        }

                    
                    }

                    // For the three parallel contacts
                        // actuationAlgorithm(4, 7);
                        // actuationAlgorithm(5, 7);
                        // actuationAlgorithm(6, 7);
                }

                

                if (Number(schemeNumber) === 3) {
                    if ((currSignObj?.names.some((name : { name: string }) => name.name.includes("automentinere")) && !selfMaintananceQ2.names.some((name : { name: string }) => name.name.includes("inchis")) && !fourthLineProblem && !fifthLineProblem && currSignObj.texts.some((text: { name: string }) => text.name === "S2")) || (currSignObj.texts.some((text: { name: string }) => text.name.includes("B.o.")) && selfMaintananceQ2.names.some((name : { name: string }) => name.name.includes("inchis")))) {
                        if (interlockQ1?.x == null || interlockQ1?.names?.some((name : { name: string }) => name.name.includes("inchis"))) {
                            if (!selfMaintananceQ2?.names?.some((name : { name: string }) => name.name.includes("deschis")) || ((!S4?.names?.some((name : { name: string }) => name.name.includes("deschis")) || nullProblem) && !S5?.names?.some((name : { name: string }) => name.name.includes("deschis")) && !currentRT?.names?.some((name : { name: string }) => name.name.includes("deschis")))) {
                                actuationAlgorithm(selfMaintananceQ2?.x || (currIdx + 1), selfMaintananceQ2?.y || currRowIdx, currSignObj.texts.some((text: { name: string }) => text.name === "B.p.") ? false : true);
                                if (interlockQ2?.x !== null) {
                                   actuationAlgorithm(interlockQ2?.x || (currIdx + 1), interlockQ2?.y || currRowIdx);
                                }
                            }
                        }

                        // For the three parallel contacts
                            // actuationAlgorithm(4, 7);
                            // actuationAlgorithm(5, 7);
                            // actuationAlgorithm(6, 7);
                    }
                }

                const intreruptor1 = findSignObjectByName(Number(schemeNumber) < 4 ? "Q1" : "Q11.1");
                const intreruptor2 = findSignObject(intreruptor1?.x + 1, intreruptor1?.y);
                const intreruptor3 = findSignObject(intreruptor1?.x + 2, intreruptor1?.y);
        
                const Q2intreruptor1 = findSignObjectByName("Q11.2");
                const Q2intreruptor2 = findSignObject(Q2intreruptor1?.x + 1, Q2intreruptor1?.y);
                const Q2intreruptor3 = findSignObject(Q2intreruptor1?.x + 2, Q2intreruptor1?.y);
        
                const Q3intreruptor1 = findSignObjectByName("Q11.3");
                const Q3intreruptor2 = findSignObject(Q3intreruptor1?.x + 1, Q3intreruptor1?.y);
                const Q3intreruptor3 = findSignObject(Q3intreruptor1?.x + 2, Q3intreruptor1?.y);
        
                const CiQ2 = findSignObjectByName("C.i Q11.2");
                const CiQ1 = findSignObjectByName("C.i Q11.1");
      

                const F4 = findSignObjectByName("F4");
                const F5 = findSignObjectByName("F5");

                const TCiQ2 = findSignObjectByName("T1 C.i. Q11.2");
                const TCiQ3 = findSignObjectByName("T1 C.i. Q11.3");


                if (!F4?.names?.some((name : { name: string }) => name.name.includes("deschis")) && !F5?.names?.some((name : { name: string }) => name.name.includes("deschis")) && currentRT?.names?.some((name : { name: string }) => name.name.includes("inchis")) && Number(schemeNumber) === 4 && currSignObj.texts.some((text: any) => text.name === "B.p.")) {
                    setTimeout(() => {
                        actuationAlgorithm(CiQ1?.x, CiQ1?.y, false);
                        actuationAlgorithm(intreruptor1?.x, intreruptor1?.y, false);
                        actuationAlgorithm(intreruptor2?.x, intreruptor2?.y, false);
                        actuationAlgorithm(intreruptor3?.x, intreruptor3?.y, false);
                    }, 1000);

                    setTimeout(() => {
                       
                        const totalCells : any = document.querySelectorAll(".cell");
                        if ([...totalCells].find((cell : any) => Number(cell.getAttribute("row")) === Number(CaQ1?.x) && Number(cell?.getAttribute("column")) === Number(CaQ1?.y)).querySelector("img")?.src?.includes("deschis")) return;
                        actuationAlgorithm(T1Q2?.x, T1Q2?.y, true);
                        actuationAlgorithm(T1Q3?.x, T1Q3?.y, false);
                        actuationAlgorithm(CiQ2?.x, CiQ2?.y, true);
                        actuationAlgorithm(TCiQ2?.x, TCiQ2?.y, false);
                        actuationAlgorithm(TCiQ3?.x, TCiQ3?.y, true);


                        actuationAlgorithm(Q2intreruptor1?.x, Q2intreruptor1?.y, true);
                        actuationAlgorithm(Q2intreruptor2?.x, Q2intreruptor2?.y, true);
                        actuationAlgorithm(Q2intreruptor3?.x, Q2intreruptor3?.y, true);

                        actuationAlgorithm(Q3intreruptor1?.x, Q3intreruptor1?.y, false);
                        actuationAlgorithm(Q3intreruptor2?.x, Q3intreruptor2?.y, false);
                        actuationAlgorithm(Q3intreruptor3?.x, Q3intreruptor3?.y, false);

                    }, 8000);

                    actuationAlgorithm(TCiQ2?.x, TCiQ2?.y, true);
                    actuationAlgorithm(CiQ2?.x, CiQ2?.y, false);
                    actuationAlgorithm(Q2intreruptor1?.x, Q2intreruptor1?.y, false);
                    actuationAlgorithm(Q2intreruptor2?.x, Q2intreruptor2?.y, false);
                    actuationAlgorithm(Q2intreruptor3?.x, Q2intreruptor3?.y, false);
                }


       
            }, 500);

            setTimeout(() => {
                removeSign(currIdx, currRowIdx, page);
                addSign(currIdx, currRowIdx, page, currSignObj.names, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
            }, 650);

        }
        else if (source.includes("/contact_normal_deschis") || source.includes("/contact_normal_inchis")) {
            const isOpen = source.includes("/contact_normal_deschis");
            const newSource = open === true ? replaceAll(source, "inchis", "deschis").slice(14) : open === false ? replaceAll(source, "deschis", "inchis").slice(14) : isOpen ? source.replaceAll("deschis", "inchis").slice(14) : source.replaceAll("inchis", "deschis").slice(14);

            removeSign(currIdx, currRowIdx, page);
            addSign(currIdx, currRowIdx, page, SCHEME_ELEMENTS[newSource.split(".")[0]].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
        }


        if (Number(schemeNumber) === 4 && currSignObj.texts.some((text: any) =>  text.name === "F4" || text.name === "F5" || text.name === "F11.1")) {
            const intreruptor1 = findSignObjectByName(Number(schemeNumber) < 4 ? "Q1" : "Q11.1");
            const intreruptor2 = findSignObject(intreruptor1?.x + 1, intreruptor1?.y);
            const intreruptor3 = findSignObject(intreruptor1?.x + 2, intreruptor1?.y);
            const Q2intreruptor1 = findSignObjectByName("Q11.2");
            const Q2intreruptor2 = findSignObject(Q2intreruptor1?.x + 1, Q2intreruptor1?.y);
            const Q2intreruptor3 = findSignObject(Q2intreruptor1?.x + 2, Q2intreruptor1?.y);
    
            const Q3intreruptor1 = findSignObjectByName("Q11.3");
            const Q3intreruptor2 = findSignObject(Q3intreruptor1?.x + 1, Q3intreruptor1?.y);
            const Q3intreruptor3 = findSignObject(Q3intreruptor1?.x + 2, Q3intreruptor1?.y);
    
            const CiQ2 = findSignObjectByName("C.i Q11.2");
            const CiQ1 = findSignObjectByName("C.i Q11.1");
            const T1Q2 = findSignObjectByName("T1.Q2");
            const T1Q3 = findSignObjectByName("T1.Q3");
    
            const TCiQ2 = findSignObjectByName("T1 C.i. Q11.2");
            const TCiQ3 = findSignObjectByName("T1 C.i. Q11.3");
            
            actuationAlgorithm(intreruptor1?.x, intreruptor1?.y, true);
            actuationAlgorithm(intreruptor2?.x, intreruptor2?.y, true);
            actuationAlgorithm(intreruptor3?.x, intreruptor3?.y, true);

      
            realActuationAlgorithm(Q3intreruptor1?.x, Q3intreruptor1?.y, true);
            realActuationAlgorithm(Q3intreruptor2?.x, Q3intreruptor2?.y, true);
            realActuationAlgorithm(Q3intreruptor3?.x, Q3intreruptor3?.y, true);

            realActuationAlgorithm(Q2intreruptor1?.x, Q2intreruptor1?.y, true);
            realActuationAlgorithm(Q2intreruptor2?.x, Q2intreruptor2?.y, true);
            realActuationAlgorithm(Q2intreruptor3?.x, Q2intreruptor3?.y, true);

            realActuationAlgorithm(T1Q2?.x, T1Q2?.y, false);
            realActuationAlgorithm(T1Q3?.x, T1Q3?.y, true);
            realActuationAlgorithm(CiQ2?.x, CiQ2?.y, true);
            realActuationAlgorithm(CiQ1?.x, CiQ1?.y, true);
            realActuationAlgorithm(TCiQ2?.x, TCiQ2?.y, false);
            realActuationAlgorithm(TCiQ3?.x, TCiQ3?.y, false);
            
        }

        const interlockQ1 = findSignObjectByName("C.i. Q11.1");
        const interlockQ2 = findSignObjectByName("C.i. Q11.2");
        

        if (Number(schemeNumber) === 3 && currSignObj?.texts?.some((text : { name: string }) => text.name === "F4" || text.name === "F5" || text.name === "F11.1")) {
            if (interlockQ1?.x !== null) {
                realActuationAlgorithm(interlockQ1?.x || (currIdx + 1), interlockQ1?.y || currRowIdx, false);
            }

            if (interlockQ2?.x !== null) {
                realActuationAlgorithm(interlockQ2?.x || (currIdx + 1), interlockQ2?.y || currRowIdx, false);
            }
        }
 
        setTimeout(() => document.querySelectorAll(".cell").forEach((cell: any) => cell.classList.remove("selected")), 200);
    };

    const realActuationAlgorithm = (currIdx : number, currRowIdx : number, open : boolean | null = null, callback : boolean | null = true) => {
        const currSignObj: any = findSignObject(currIdx, currRowIdx);
        const currSign: any = findSign(currIdx, currRowIdx);
        console.log(currSign, currSignObj);

        if (!currSign) return;
        const source = currSign?.props?.children?.[0]?.[0]?.props?.src;
        if (!(source.includes("/siguranta") && !source.includes("siguranta_automata")) && !(source.includes("/contact_normal_deschis") || source.includes("/contact_normal_inchis"))) return;

        if (source.includes("/siguranta") && !source.includes("siguranta_automata")) {
            const isOpen = source.includes("/siguranta_deschisa");
            removeSign(currIdx, currRowIdx, page);
            if (open !== null ? !open : isOpen) addSign(currIdx, currRowIdx, page, SCHEME_ELEMENTS["siguranta"].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
            else addSign(currIdx, currRowIdx, page, SCHEME_ELEMENTS["siguranta_deschisa"].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
        } else if (source.includes("actionare_prin_apasare") && Number(schemeNumber) !== 1) {
            const isOpen = source.includes("/contact_normal_deschis");
            const newSource = (open !== null ? !open : isOpen) ? replaceAll(source, "deschis", "inchis").slice(14) : replaceAll(source, "inchis", "deschis").slice(14);
            const realNewSource = newSource.split(".")[0];

            removeSign(currIdx, currRowIdx, page);
            addSign(currIdx, currRowIdx, page, SCHEME_ELEMENTS[realNewSource].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
            const selfMaintananceQ1 = findSignObjectByName("C.a. Q1");
            const selfMaintananceQ2 = findSignObjectByName("C.a. Q11.2");
            const interlockQ1 = findSignObjectByName("C.i. Q11.1");
            const interlockQ2 = findSignObjectByName("C.i. Q11.2");
            const BP1 = findSignObjectByName(Number(schemeNumber) === 2 ? "B.p." : "B.p.");

            // const selfMaintananceQ11 = findSignObjectByName("Q11.1.");
            // const selfMaintananceQ12 = findSignObjectByName("Q11.2.");


            // const selfMaintananceQ1 = findSignObject(selfMaintananceQ1Obj?.x || (currIdx + 1), selfMaintananceQ1Obj?.y || currRowIdx);

            setTimeout(() => {
                if (Number(schemeNumber) === 1) return;
                const nullList = Number(schemeNumber) === 2 ? [20, 21, 38, 39, 40, 41, 77, 76] : [];
                const fifthTotalLinePoints = Number(schemeNumber) === 2 ? [22, 23, 24, 25, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 64, 65, 66, 67, 74, 75] : selfMaintananceQ1?.names?.some((name : { name: string }) => name.name.includes("inchis")) ? [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 54, 55, 58, 62, 60, 61, 63, 59, 56, 57, 74, 75, 76, 77, 94, 95] : selfMaintananceQ2?.names?.some((name : { name: string }) => name.name.includes("inchis")) ? [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 64, 65, 68, 72, 70, 71, 73, 69, 66, 67, 78, 79, 80, 81, 98, 99] : [];
                const fourthTotalLinePoints = Number(schemeNumber) === 2 ? schemeStructure.filter((element: { line: string }) => element.line.includes("Nul") || element.line.includes("N")).map((elem : any) => elem.dotNumber) : selfMaintananceQ1?.names?.some((name : { name: string }) => name.name.includes("inchis")) ? [96, 97] : [100, 101];

                const nullProblem = problems.some((element: { point: number, type: string | null }) => nullList.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved);
                const fifthLineProblem = problems.some((element: { point: number, type: string | null }) => fifthTotalLinePoints.includes(element.point) && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved);
                const fourthLineProblem = problems.some((element: { point: number, type: string | null }) => fourthTotalLinePoints.includes(element.point) && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved);

                if ((currSignObj?.names?.some((name : { name: string }) => name.name.includes("automentinere")) && !selfMaintananceQ1?.names?.some((name : { name: string }) => name.name.includes("inchis")) && !fifthLineProblem && !fourthLineProblem && (Number(schemeNumber) === 2 || currSignObj.texts.some((text: { name: string }) => text.name === "S1"))) || (currSignObj.texts.some((text: { name: string }) => text?.name?.includes("B.o.")) && selfMaintananceQ1?.names?.some((name : { name : string }) => name.name.includes("inchis")))) {
                    console.log(interlockQ2);
                    if (interlockQ2?.x == null || interlockQ2?.names?.some((name : { name: string }) => name.name.includes("inchis"))) {
                        if (!selfMaintananceQ1?.names.some((name : { name : string }) => name.name.includes("deschis")) || ((!S4?.names?.some((name : { name: string }) => name.name.includes("deschis")) || nullProblem) && !S5?.names?.some((name : { name: string }) => name.name.includes("deschis")) && !currentRT?.names?.some((name : { name: string }) => name.name.includes("deschis")))) {
                            //alert("Close");
                            realActuationAlgorithm(selfMaintananceQ1?.x || (currIdx + 1), selfMaintananceQ1?.y || currRowIdx, currSignObj.texts.some((text: { name: string }) => text.name === "B.p.") ? false : true);
                            // if (callback) setTimeout(() => actuationAlgorithm(BP1?.x || 0, BP1?.y || 0, false, false), 2000);
                            if (interlockQ1?.x !== null) {
                                realActuationAlgorithm(interlockQ1?.x || (currIdx + 1), interlockQ1?.y || currRowIdx);
                            }
                        }
                    }

                    // For the three parallel contacts
                        // actuationAlgorithm(4, 7);
                        // actuationAlgorithm(5, 7);
                        // actuationAlgorithm(6, 7);
                }

                

                if (Number(schemeNumber) !== 2 && Number(schemeNumber) !== 1) {
                    if ((currSignObj.names?.some((name : { name :string }) => name.name.includes("automentinere")) && !selfMaintananceQ2?.names?.some((name : { name : string }) => name.name.includes("inchis")) && !fourthLineProblem && !fifthLineProblem && currSignObj.texts.some((text: { name: string }) => text.name === "S2")) || (currSignObj.texts.some((text: { name: string }) => text.name.includes("B.o.")) && selfMaintananceQ2?.names?.some((name : { name: string }) => name.name.includes("inchis")))) {
                        if (interlockQ1?.x == null || interlockQ1?.names?.some((name : { name : string }) => name.name.includes("inchis"))) {
                            if (!selfMaintananceQ2?.names?.some((name : { name: string }) => name.name.includes("deschis")) || ((!S4?.names?.some((name : { name: string }) => name.name.includes("deschis")) || nullProblem) && !S5?.names?.some((name : { name: string }) => name.name.includes("deschis")) && !currentRT?.names?.some((name : { name: string }) => name.name.includes("deschis")))) {
                                realActuationAlgorithm(selfMaintananceQ2?.x || (currIdx + 1), selfMaintananceQ2?.y || currRowIdx, currSignObj.texts.some((text: { name: string }) => text.name === "B.p.") ? false : true);
                                if (interlockQ2?.x !== null) {
                                    realActuationAlgorithm(interlockQ2?.x || (currIdx + 1), interlockQ2?.y || currRowIdx);
                                }
                            }
                        }

                        // For the three parallel contacts
                            // actuationAlgorithm(4, 7);
                            // actuationAlgorithm(5, 7);
                            // actuationAlgorithm(6, 7);
                    }
                }
       
            }, 500);

            setTimeout(() => {
                removeSign(currIdx, currRowIdx, page);
                addSign(currIdx, currRowIdx, page, currSignObj.names, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
            }, 650);

        }

        else if (source.includes("/contact_normal_deschis") || source.includes("/contact_normal_inchis")) {
            const isOpen = source.includes("/contact_normal_deschis");
            const newSource = (open !== null ? open : !isOpen) ? replaceAll(source, "inchis", "deschis").slice(14) : replaceAll(source, "deschis", "inchis").slice(14);
            removeSign(currIdx, currRowIdx, page);
            addSign(currIdx, currRowIdx, page, SCHEME_ELEMENTS[newSource.split(".")[0]].imageSource, currSignObj.flip, currSignObj.texts, currSignObj.signColor || "#000", currSignObj?.signPosition || "");
        }


       

        if (Number(schemeNumber) === 4 && currSignObj.texts.some((text: any) =>  text.name === "F4" || text.name === "F5" || text.name === "F11.1")) {
            const intreruptor1 = findSignObjectByName(Number(schemeNumber) < 4 ? "Q1" : "Q11.1");
            const intreruptor2 = findSignObject(intreruptor1?.x + 1, intreruptor1?.y);
            const intreruptor3 = findSignObject(intreruptor1?.x + 2, intreruptor1?.y);
            const Q2intreruptor1 = findSignObjectByName("Q11.2");
            const Q2intreruptor2 = findSignObject(Q2intreruptor1?.x + 1, Q2intreruptor1?.y);
            const Q2intreruptor3 = findSignObject(Q2intreruptor1?.x + 2, Q2intreruptor1?.y);
    
            const Q3intreruptor1 = findSignObjectByName("Q11.3");
            const Q3intreruptor2 = findSignObject(Q3intreruptor1?.x + 1, Q3intreruptor1?.y);
            const Q3intreruptor3 = findSignObject(Q3intreruptor1?.x + 2, Q3intreruptor1?.y);
    
            const CiQ2 = findSignObjectByName("C.i Q11.2");
            const CiQ1 = findSignObjectByName("C.i Q11.1");
            const T1Q2 = findSignObjectByName("T1.Q2");
            const T1Q3 = findSignObjectByName("T1.Q3");
    
            const TCiQ2 = findSignObjectByName("T1 C.i. Q11.2");
            const TCiQ3 = findSignObjectByName("T1 C.i. Q11.3");
            
            realActuationAlgorithm(intreruptor1?.x, intreruptor1?.y, true);
            realActuationAlgorithm(intreruptor2?.x, intreruptor2?.y, true);
            realActuationAlgorithm(intreruptor3?.x, intreruptor3?.y, true);

      
            realActuationAlgorithm(Q3intreruptor1?.x, Q3intreruptor1?.y, true);
            realActuationAlgorithm(Q3intreruptor2?.x, Q3intreruptor2?.y, true);
            realActuationAlgorithm(Q3intreruptor3?.x, Q3intreruptor3?.y, true);

            realActuationAlgorithm(Q2intreruptor1?.x, Q2intreruptor1?.y, true);
            realActuationAlgorithm(Q2intreruptor2?.x, Q2intreruptor2?.y, true);
            realActuationAlgorithm(Q2intreruptor3?.x, Q2intreruptor3?.y, true);

            realActuationAlgorithm(T1Q2?.x, T1Q2?.y, false);
            realActuationAlgorithm(T1Q3?.x, T1Q3?.y, true);
            realActuationAlgorithm(CiQ2?.x, CiQ2?.y, true);
            realActuationAlgorithm(CiQ1?.x, CiQ1?.y, true);
            realActuationAlgorithm(TCiQ2?.x, TCiQ2?.y, false);
            realActuationAlgorithm(TCiQ3?.x, TCiQ3?.y, false);
            
            

        }
 
        setTimeout(() => document.querySelectorAll(".cell").forEach((cell: any) => cell.classList.remove("selected")), 200);
    };

    useEffect(() => {
        if (category === "0") return;
        const currentSignBulb = findSignObject(T1Q2?.x + 1, T1Q2?.y + 2, 1);

        if (T1Q2?.names?.some((name : { name: string }) => name.name.includes("inchis")) && CaQ1?.names?.some((name : { name: string }) => name.name.includes("inchis"))) {
            replaceSign(currentSignBulb?.x, currentSignBulb?.y, 1, SCHEME_ELEMENTS["indicator_luminos_aprins"].imageSource);
        } else {
            replaceSign(currentSignBulb?.x, currentSignBulb?.y, 1, SCHEME_ELEMENTS["indicator_luminos"].imageSource);
        }
    }, [T1Q2, T1Q3, CaQ1]);

    useEffect(() => {
        if (category === "0") return;
        const currentSignBulb = findSignObject(T1Q3?.x + 1, T1Q3?.y + 2, 1);

        if (T1Q3?.names?.some((name : { name: string }) => name.name.includes("inchis")) && CaQ1?.names?.some((name : { name: string }) => name.name.includes("inchis"))) {
            replaceSign(currentSignBulb?.x, currentSignBulb?.y, 1, SCHEME_ELEMENTS["indicator_luminos_aprins"].imageSource);
        } else {
            replaceSign(currentSignBulb?.x, currentSignBulb?.y, 1, SCHEME_ELEMENTS["indicator_luminos"].imageSource);
        }
    }, [T1Q3, T1Q2, CaQ1]);

    const throttle = (callback : any, delay : number) => {
        let lastCall : number = 0;
        return (...args : any) => {
            const now = new Date().getTime();
            if (now - lastCall >= delay) {
                callback(...args);
                lastCall = now;
            }
        };
    };

    // useEffect(() => {
    //     if (!cellRef?.current) return;

    //     const mouseMove = (e : MouseEvent) => {
    //         moveElement(e);
    //     };
        
    //     cellRef.forEach((cell : any) => {
    //         cell.addEventListener("mousemove", mouseMove);
    //     });

    //     return () => {
    //         cellRef.forEach((cell : any) => {
    //             cell.removeEventListener("mousemove", mouseMove);
    //         })
    //     };

    // }, [cellRef]);

    return (<div
                    {...otherProps}
                    className={`cell ${hasCurrent ? "has-current" : ""} ${copiedElement?.x === idx && copiedElement?.y === rowIdx ? "copy" : ""} ${electricityLine ? electricityLine : ""} ${isSelectedCell(idx, rowIdx) ? "selected" : ""} ${foundSignObject?.signPosition || "horizontal"}`}
                    key={rowIdx * COLUMNS + idx}

                    ref={ref => cellRef.current[rowIdx * COLUMNS + idx] = ref}
                    style={copiedElement?.x === idx && copiedElement?.y === rowIdx ? {
                        border: "2px dotted #000"
                    } : {}}
                    onMouseDown={(e : any) => {
                        console.log(e.button);
                        if (e.button === 2) return;
                        // if (e.which === 3) return;
                        if (!cellRef.current[rowIdx * COLUMNS + idx]?.firstChild) return;
                        // if (e.target.tagName.toLowerCase() === "h2") return;
                        startDrag = true;
                        setTimeout(() => {
                            
                            if (dragElement) {
                                setSingleElementDrag(true);
                            }
                        }, 300);


                        setTimeout(() => {
                            if (!startDrag) return;
                            initiateElement(e, cellRef.current[rowIdx * COLUMNS + idx], idx, rowIdx);
                        }, 150);
                    }}
                    onMouseMove={(e : any) => moveElement(e)}
                    onMouseUp={() => {
                        startDrag=false;
                        stopElement();
                    }}
                    onTouchStart={e => {
                        if (!cellRef.current[rowIdx * COLUMNS + idx]?.firstChild) return;
                        touchInitiateElement(e, cellRef.current[rowIdx * COLUMNS + idx], idx, rowIdx)
                    }}
                    onTouchMove={e => touchMoveElement(e)}
                    onTouchEnd={() => stopElement(true)}
                    onContextMenu={(e : any) => {
                        if (e.button === 3) e.preventDefault();
                    }}
                    onClick={(e: any) => {
                        if (e.target.tagName.toLowerCase === "h2") return;
                        console.log(idx, rowIdx, foundSignObject, foundSignObject?.names, cellRef);
                        let currFoundSignObject = foundSignObject;
                        if (Number(schemeNumber) !== 1 && category !== "0" && !foundSignObject?.names?.length && findSignObject(idx + 1, rowIdx)?.names?.some((name : { name: string }) => name.name.includes("actionare_prin_apasare"))) currFoundSignObject = findSignObject(idx + 1, rowIdx);
                        else if (category !== "0" && findSignObject(idx, rowIdx + 1)?.names?.some((name : { name: string }) => name.name.includes("actionare_prin_apasare")) && findSignObject(idx, rowIdx + 1)?.flip % 180 === 90) currFoundSignObject = findSignObject(idx, rowIdx + 1);


                        if (category !== "0" && !currFoundSignObject?.names?.length) return;
                        if (category !== "0" && (e.target.classList.contains("dot") || e.target?.parentElement?.tagName?.toLowerCase() === "p")) return;
                        const screwDriverChildren : any = document.querySelectorAll(".screw-driver-container *");
                        if (category !== "0" && [...screwDriverChildren].some((elem: any) => elem === e.target)) return;
                        
                        console.log(category);
                        if (category !== "0" && currFoundSignObject?.names?.length && currFoundSignObject?.names?.some((name : { name: string }) => name.name.includes("contact_normal")) && !currFoundSignObject.names?.some((name : { name: string }) => name.name.includes("actionare")) && currFoundSignObject.names?.some((name : { name: string }) => name.name.includes("automentinere"))) return;
                        if ( category !== "0" && currFoundSignObject?.texts?.length && currFoundSignObject.texts.some( (text: { name: string }) => text.name === "5" || text.name === "1" || text.name === "3" || text.name === "7" || text.name === "9" || text.name === "11" || text.name === "13" || text.name === "15" || text.name === "17" || text.name === "C.i. Q11.1" || text.name === "C.i. Q11.2" || text.name.includes("T1.")) ) return;
                        console.log(currFoundSignObject);
                        const S1 = findSignObjectByName("F1");
                        const S2 = findSignObjectByName("F2");
                        const S3 = findSignObjectByName("F3");
                        const contact = findSignObjectByName("I");
                        
                        if (category !== "0" && ((currFoundSignObject.names?.some((name : { name: string }) => name.name.includes("automentinere")) && currFoundSignObject.names?.some((name : { name: string }) => name.name.includes("actionare_prin_apasare")) && (S3.names?.some((name : { name: string }) => name.name.includes("deschis")) || S2.names?.some((name : { name: string }) => name.name.includes("deschis")) || S1.names?.some((name : { name: string }) => name.name.includes("deschis")))) || (currFoundSignObject.names?.some((name : { name: string }) => name.name.includes("siguranta")) && currFoundSignObject.texts.some((text : { name: string }) => text.name === "F1" || text.name === "F2" || text.name === "F3")))) {
                            const currentRT = findSignObjectByName(Number(schemeNumber) === 2 ? "R.T." : Number(schemeNumber) === 3 ? "F11.1" : 'F11.1');
                            
                            setTimeout(() => {
                                
                                // console.log(S1Copy, S2Copy, S3Copy);
                                // if (!((currFoundSignObject.name.includes("automentinere") && currFoundSignObject.name.includes("actionare_prin_apasare") && (S3.name.includes("deschis") || S2.name.includes("deschis") || S1.name.includes("deschis"))) || (currFoundSignObject.name.includes("siguranta") && currFoundSignObject.texts.some((text : { name: string }) => text.name === "F1" || text.name === "F2" || text.name === "F3")))) return;
                                const cells = Array.from(document.querySelectorAll(".cell"));
                                const S1Copy = cells.find((cell : any) => Number(cell.getAttribute("row")) === S1.x && Number(cell.getAttribute("column")) === S1.y)?.querySelector("img")?.src;
                                const S2Copy = cells.find((cell : any) => Number(cell.getAttribute("row")) === S2.x && Number(cell.getAttribute("column")) === S2.y)?.querySelector("img")?.src;
                                const S3Copy = cells.find((cell : any) => Number(cell.getAttribute("row")) === S3?.x && Number(cell.getAttribute("column")) === S3?.y)?.querySelector("img")?.src || "";
                            
                                console.log(S1Copy, S2Copy, S3Copy);
                                if (!S1Copy?.includes("deschis") && !S2Copy?.includes("deschis") && !S3Copy?.includes("deschis")) return;
                                
                                // alert("1");

                                actuationAlgorithm(currentRT?.x, currentRT?.y, true);

                                setTimeout(() => {
                                    const currentBO = findSignObjectByName("B.o");
                                    if (currentBO?.names?.some((name : { name: string }) => name.name.includes("deschis"))) realActuationAlgorithm(currentBO?.x, currentBO?.y, false);
                                    if (Number(schemeNumber) === 3) {
                                        const interlockQ1 = findSignObjectByName("C.i. Q11.1");
                                        const interlockQ2 = findSignObjectByName("C.i. Q11.2");
                                        
                                        if (interlockQ1?.x != null) actuationAlgorithm(interlockQ1?.x, interlockQ1?.y, false);
                                        if (interlockQ2?.x != null) actuationAlgorithm(interlockQ2?.x, interlockQ2?.y, false);
                                    }
                                }, 2000);
                                
                                setTimeout(() => {
                                    actuationAlgorithm(currentRT?.x, currentRT?.y, false);
                                }, 4000);

                                
                            }, 4000);
                        }

                        // !!! Get Electricity

                        getElectricity(); 

                        if (category !== "0") {
                            actuationAlgorithm(currFoundSignObject?.x, currFoundSignObject?.y);


                            setTimeout(() => {
                                console.log(problems);
                                
                                if (Number(schemeNumber) === 1) {
                                    const firstShortcircuitDefect = problems.find((element: { point: number, type: string | null }) => element.point >= 3 && element.point <= 18 && element.type === "Scurt circuit")?.point || -1;
                                    if (schemeStructure.some((element : { dotNumber: number, screw: number }) => {
                                        // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                                        return element.dotNumber < firstShortcircuitDefect && element.screw <= .25;
                                    })) return;
        
                                    if (problems.some((element: { point: number, type: string | null }) => element.point >= 3 && element.point <= 10 && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
    
                                        actuationAlgorithm(S1.x, S1.y, false);
                                    } else if (problems.some((element: { point: number, type: string | null }) => element.point >= 11 && element.point <= 18 && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                                        if (contact.names?.some((name : { name: string }) => name.name.includes("inchis")) || JSON.stringify(currFoundSignObject?.names || []) === JSON.stringify(contact?.names || [])) {
                                            actuationAlgorithm(S1.x, S1.y, false);
                                        }
                                    }
                                } else if (Number(schemeNumber) === 2) {
                                    const contactS1 = findSignObjectByName("Q1");
                                    const contactS3 = findSignObjectByName("Q1S3");
                                    const contactS5 = findSignObjectByName("C.a. Q1");
                                    
                                    const firstTotalLinePoints = [8, 9, 10, 26, 27, 28, 29, 52, 53, 54, 55, 70, 71];
                                    const firstRestLinePoints = [28, 29, 52, 53, 54, 55, 70, 71];
                                    const secondTotalLinePoints = [13, 14, 31, 32, 33, 34, 57, 58, 59, 60, 69, 70];
                                    const secondRestLinePoints = [33, 34, 57, 58, 59, 60, 69, 70];
                                    const thirdTotalLinePoints = [16, 17, 34, 35, 36, 37, 60, 61, 62, 63, 72, 73];
                                    const thirdRestLinePoints = [36, 37, 60, 61, 62, 63, 72, 73];
                                    const fifthTotalLinePoints = [24, 25, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 64, 65, 66, 67, 74, 75];
                                    const fifthRestLinePoints = [44, 45,  47, 49, 51, 64, 65, 66, 67, 74, 75];

                                    let firstShortcircuitDefectIdx : number = -1, thirdShortcircuitDefectIdx : number = -1, fifthShortcircuitDefectIdx : number = -1;
                                    const firstShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                                        if (firstTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                                            firstShortcircuitDefectIdx = idx;
                                            return true;
                                        }

                                        return false;
                                    });

                                    const thirdShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                                        if (thirdTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                                            thirdShortcircuitDefectIdx = idx;
                                            return true;
                                        }

                                        return false;
                                    });

                                    const fifthShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                                        if (fifthTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                                            fifthShortcircuitDefectIdx = idx;
                                            return true;
                                        }
                                        return false;
                                    });


                                    // For S1

                                    if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx : number) => {
                                        // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                                        return idx < firstShortcircuitDefectIdx && element.screw <= .25 && firstShortcircuitDefect.line === element.line;
                                    })) {
                                        if (problems.some((element: { point: number, type: string | null }) => !firstRestLinePoints.includes(element.point) && firstTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
    
                                            actuationAlgorithm(S1.x, S1.y, false);
                                        } else if (problems.some((element: { point: number, type: string | null }) => firstRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                                            // if (schemeStructure.find((element: { dotNumber: number }) => element.dotNumber === 52)?.open === false) {
                                                // if (contactS1?.name?.includes("inchis")) {
                                                    if (foundSignObject?.names?.some((name : { name: string }) => name.name.includes("actionare_prin_apasare_automentinere"))) {
                                                        setTimeout(() => {
                                                        // alert("PASS 2");
        
                                                            let currentContact, S3Contact;
                                                            document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === contactS1?.y && Number(cell.getAttribute("row")) === contactS1?.x ? currentContact = cell?.querySelector("img")?.src?.includes("inchis") : null);
                                                            document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === contactS3?.y && Number(cell.getAttribute("row")) === contactS3?.x ? S3Contact = cell?.querySelector("img")?.src?.includes("inchis") : null);

                                                            if (currentContact) {
                                                                actuationAlgorithm(S1.x, S1.y, false);
                                                            }

                                                            if (currentContact) {
                                                                setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, true), 4000);
                                                                setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, false), 5000);
                                                            }
                                                        }, 1000);
                                                        
                                                    }    
                                                // }

                                            // }
                                        }
                                    }
        

                                    // For S3
                                    if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx: number) => {
                                        // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                                        return idx < thirdShortcircuitDefectIdx && element.screw <= .25 && thirdShortcircuitDefect.line === element.line;
                                    })) {
                                        if (problems.some((element: { point: number, type: string | null }) => !thirdRestLinePoints.includes(element.point) && thirdTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
                                            actuationAlgorithm(S3.x, S3.y, false);
                                        } else if (problems.some((element: { point: number, type: string | null }) => thirdRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                                            console.log(schemeStructure);
                                            if (foundSignObject?.names?.some((name : { name: string }) => name.name.includes("actionare_prin_apasare_automentinere"))) {
                                                setTimeout(() => {
                                                // alert("PASS 2");

                                                    let currentContact;
                                                    document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === contactS3?.y && Number(cell.getAttribute("row")) === contactS3?.x ? currentContact = cell?.querySelector("img")?.src?.includes("inchis") : null);

                                                    if (currentContact) {
                                                        actuationAlgorithm(S3.x, S3.y, false);
                                                    } 

                                                    if (currentContact) {
                                                        setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, true), 4000);
                                                        setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, false), 5000);
                                                    }
                                                }, 1000);
                                               
                                            }
                                        }
                                    }


                                    // For S5
                                    if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx: number) => {
                                        return idx < fifthShortcircuitDefectIdx && element.screw <= .25 && fifthShortcircuitDefect.line === element.line;
                                    })) {
                                        if (problems.some((element: { point: number, type: string | null }) => !fifthRestLinePoints.includes(element.point) && fifthTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
                                            
                                            actuationAlgorithm(S5.x, S5.y, false);
                                        } else if (problems.some((element: { point: number, type: string | null }) => fifthRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                                            if (foundSignObject?.names?.some((name : { name: string }) => name.name.includes("actionare_prin_apasare_automentinere"))) {
                                                setTimeout(() => {
                                                // alert("PASS 2");

                                                    let currentContact;
                                                    document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === contactS5?.y && Number(cell.getAttribute("row")) === contactS5?.x ? currentContact = cell?.querySelector("img")?.src?.includes("inchis") : null);
                                                    if (currentContact) {
                                                        actuationAlgorithm(S5.x, S5.y, false);
                                                        setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, true), 4000);
                                                        setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, false), 5000);
                                                    }
                                                }, 1000);
                                               
                                            }
                                             
                                            
                                        }
                                    }
                                } else if (Number(schemeNumber) === 3) {
                                  
                                    const secondContactS1 = findSignObjectByName("Q11.2");
                                    const secondContactS3 = findSignObjectByName("Q11.2S3");
                                    const selfMaintananceQ1 = findSignObjectByName("C.i. Q11.1");
                                    const selfMaintananceQ2 = findSignObjectByName("C.i. Q11.2");
                                    const autoMaintananceQ2 = findSignObjectByName("C.a. Q11.2");

                                    let currentSecondContact : any, currentSecondContactS3, openAutomantananceQ2 : any;
                                    document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === secondContactS1?.y && Number(cell.getAttribute("row")) === secondContactS1?.x ? currentSecondContact = cell?.querySelector("img")?.src?.includes("deschis") : null);
                                    document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === autoMaintananceQ2?.y && Number(cell.getAttribute("row")) === autoMaintananceQ2?.x ? openAutomantananceQ2 = cell?.querySelector("img")?.src?.includes("deschis") : null);

                                    const contactS1 = currentSecondContact ? findSignObjectByName("Q11.1") : secondContactS1;
                                    const contactS3 = currentSecondContact ? findSignObjectByName("Q11.1S3") : secondContactS3;
                                    const autoMaintanance = openAutomantananceQ2 ? findSignObjectByName("C.a. Q1") : findSignObjectByName("C.a. Q11.2");


                                    const firstTotalLinePoints = currentSecondContact ? [6, 7, 8, 9, 50, 51, 30, 31, 32, 33, 44, 45, 82, 83, 84, 85, 104, 105] : [6, 7, 8, 9, 30, 31, 50, 51, 52, 53, 41, 40, 90, 91, 92, 93, 106, 107];
                                    const firstRestLinePoints = currentSecondContact ?  [32, 33, 44, 45, 82, 83, 84, 85, 104, 105] : [52, 53, 41, 40, 90, 91, 92, 93, 106, 107];
                                    const secondTotalLinePoints = [10, 11, 12, 13, 46, 47, 34, 35, 36, 37, 48, 49, 86, 87, 88, 89, 102, 103];
                                    const secondRestLinePoints = [36, 37, 48, 49, 86, 87, 88, 89, 102, 103];
                                    const thirdTotalLinePoints = currentSecondContact ? [14, 15, 16, 17, 38, 39, 42, 43, 40, 41, 52, 53, 90, 91, 92, 93, 106, 107] : [14, 15, 16, 17, 38, 39, 42, 43, 44, 45, 32, 33, 82, 83, 84, 85, 104, 105];
                                    const thirdRestLinePoints = currentSecondContact ? [40, 41, 52, 53, 90, 91, 92, 93, 106, 107] : [44, 45, 32, 33, 82, 83, 84, 85, 104, 105];

                                    const fifthTotalLinePoints = openAutomantananceQ2 ? [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 54, 55, 58, 62, 60, 61, 63, 59, 56, 57, 74, 75, 76, 77, 94, 95] : [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 64, 65, 68, 72, 70, 71, 73, 69, 66, 67, 78, 79, 80, 81, 98, 99];
                                    const fifthRestLinePoints = openAutomantananceQ2 ? [61, 63, 59, 56, 57, 74, 75, 76, 77, 94, 95] : [71, 73, 69, 66, 67, 78, 79, 80, 81, 98, 99];

                                    let firstShortcircuitDefectIdx : number = -1, thirdShortcircuitDefectIdx : number = -1, fifthShortcircuitDefectIdx : number = -1;
                                    const firstShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                                        if (firstTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                                            firstShortcircuitDefectIdx = idx;
                                            return true;
                                        }

                                        return false;
                                    });

                                    const thirdShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                                        if (thirdTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                                            thirdShortcircuitDefectIdx = idx;
                                            return true;
                                        }

                                        return false;
                                    });

                                    const fifthShortcircuitDefect = problems.find((element: { point: number, type: string | null }, idx: number) => {
                                        if (fifthTotalLinePoints.includes(element.point) && element.type === "Scurt circuit") {
                                            fifthShortcircuitDefectIdx = idx;
                                            return true;
                                        }
                                        return false;
                                    });


                                    // For S1

                                    if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx : number) => {
                                        // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                                        return idx < firstShortcircuitDefectIdx && element.screw <= .25 && firstShortcircuitDefect.line === element.line;
                                    })) {
                                        if (problems.some((element: { point: number, type: string | null }) => !firstRestLinePoints.includes(element.point) && firstTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
    
                                            actuationAlgorithm(S1.x, S1.y, false);
                                        } else if (problems.some((element: { point: number, type: string | null }) => firstRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                                                console.log(contactS1);
                                                // if (foundSignObject?.name?.includes("actionare_prin_apasare_automentinere")) {
                                                    setTimeout(() => {
                                                        // alert("PASS 2");
    
                                                        let currentContact, otherContact;
                                                        document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === contactS1?.y && Number(cell.getAttribute("row")) === contactS1?.x ? currentContact = cell?.querySelector("img")?.src?.includes("inchis") : null);
                                                        // document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === contactS3?.y && Number(cell.getAttribute("row")) === contactS3?.x ? otherContact = cell?.querySelector("img")?.src?.includes("inchis") : null);

                                                        if (currentContact) {
                                                            // alert("PASS 3");
                                                            actuationAlgorithm(S1.x, S1.y, false);

                                                        } //else if (otherContact) {
                                                        //     actuationAlgorithm(S3.x, S3.y, false);
                                                        // }

                                                        if (currentContact) {
                                                            setTimeout(() => {
                                                                // Actuating self maintanances
                                                                // let copyS1 = findSignObjectByName()
                                                                if (currentSecondContact === true) actuationAlgorithm(selfMaintananceQ1.x, selfMaintananceQ1.y, false);
                                                                else if (currentSecondContact === false) actuationAlgorithm(selfMaintananceQ2.x, selfMaintananceQ2.x, false);
                                                            }, 4000);
                                                            setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, true), 4000);
                                                            setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, false), 5000);
                                                        }
                                                    }, 500);
                                                   
                                                // }
                                                
                                        }
                                    }
                                    
                                    console.log(problems, thirdRestLinePoints);

                                    // For S3
                                    if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx: number) => {
                                        // if (element.dotNumber < 3 || element.dotNumber > 18) return;
                                        return idx < thirdShortcircuitDefectIdx && element.screw <= .25 && thirdShortcircuitDefect.line === element.line;
                                    })) {
                                        if (problems.some((element: { point: number, type: string | null }) => !thirdRestLinePoints.includes(element.point) && thirdTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
                                            actuationAlgorithm(S3.x, S3.y, false);
                                        } else if (problems.some((element: { point: number, type: string | null }) => thirdRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                                            console.log(schemeStructure);
                                            // if (foundSignObject?.name?.includes("actionare_prin_apasare_automentinere")) {
                                                setTimeout(() => {
                                                // alert("PASS 2");

                                                    let currentContact;
                                                    document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === contactS3?.y && Number(cell.getAttribute("row")) === contactS3?.x ? currentContact = cell?.querySelector("img")?.src?.includes("inchis") : null);
                                                    if (currentContact) {
                                                        actuationAlgorithm(S3.x, S3.y, false);
                                                        setTimeout(() => {
                                                            // Actuating self maintanances
                                                            if (currentSecondContact === true) actuationAlgorithm(selfMaintananceQ1.x, selfMaintananceQ1.y, false);
                                                            else if (currentSecondContact === false) actuationAlgorithm(selfMaintananceQ2.x, selfMaintananceQ2.x, false);
                                                        }, 4000);
                                                        setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, true), 4000);
                                                        setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, false), 5000);
                                                    }
                                                }, 500);
                                               
                                            // }
                                        }
                                    }


                                    // For S5
                                    if (!schemeStructure.some((element : { dotNumber: number, screw: number, line: string }, idx: number) => {
                                        return idx < fifthShortcircuitDefectIdx && element.screw <= .25 && fifthShortcircuitDefect.line === element.line;
                                    })) {
                                        if (problems.some((element: { point: number, type: string | null }) => !fifthRestLinePoints.includes(element.point) && fifthTotalLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber.toString() === element.point.toString()).solved)) {
                                            
                                            actuationAlgorithm(S4.x, S4.y, false);
                                        } else if (problems.some((element: { point: number, type: string | null }) => fifthRestLinePoints.includes(element.point) && element.type === "Scurt circuit" && !schemeStructure.find((scElement: any) => scElement.dotNumber === element.point).solved)) {
                                            if (foundSignObject?.names?.some((name : { name: string }) => name.name.includes("actionare_prin_apasare_automentinere"))) {
                                                setTimeout(() => {
                                                // alert("PASS 2");

                                                    let currentContact;
                                                    document.querySelectorAll(".cell").forEach((cell: any) => Number(cell.getAttribute("column")) === autoMaintanance?.y && Number(cell.getAttribute("row")) === autoMaintanance?.x ? currentContact = cell?.querySelector("img")?.src?.includes("inchis") : null);
                                                    if (currentContact) {
                                                        actuationAlgorithm(S4.x, S4.y, false);
                                                        
                                                        if (openAutomantananceQ2 === true) actuationAlgorithm(selfMaintananceQ1.x, selfMaintananceQ1.y, false);
                                                        else if (openAutomantananceQ2 === false) actuationAlgorithm(selfMaintananceQ2.x, selfMaintananceQ2.x, false);
                                                        
                                                        // setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, true), 4000);
                                                        // setTimeout(() => actuationAlgorithm(currentRT?.x, currentRT?.y, false), 5000);
                                                    }
                                                }, 500);
                                               
                                            }
                                             
                                            
                                        }
                                    }
                                }
                            
                            }, 500);

                            return;
                        }
                        
                        if (!copiedElement && !cuttedElement && !multipleSelectedCells) document.querySelectorAll(".cell").forEach((cell: any) => cell.classList.remove("selected"));
                        console.log(copiedElement, cuttedElement, currFoundSignObject);
                        if (isImageLocked) return;
                        if (!copiedElement && !cuttedElement && !multipleSelectedCells) changeSelectedCell(currFoundSignObject?.x, currFoundSignObject?.y, page);

                        console.log(currentFoundSign, isSelectedCell(currFoundSignObject?.x, currFoundSignObject?.y));
                        if (currentFoundSign) {
                            if (!copiedElement && !cuttedElement && !multipleSelectedCells) changeIsSelectedSign(currFoundSignObject?.x, currFoundSignObject?.y, page);
                            cellRef.current[currFoundSignObject?.y * COLUMNS + currFoundSignObject?.x]?.classList.toggle("selected");
                        }
                        else {
                            console.log("a", rowIdx, idx);
                            if (!copiedElement && !cuttedElement && !multipleSelectedCells) {
                                removeSelectedSign();
                                changeSelectedCell(idx, rowIdx, page);
                            }
                            // cellRef.current[rowIdx * COLUMNS + idx]?.classList.toggle("selected");
                            // document.querySelectorAll("cell").forEach((cell) => {
                            //     if (cell.getAttribute("row") === rowIdx.toString() && cell.getAttribute("column") === idx.toString()) cell.classList.toggle("selected")
                            // });
                            cellRef.current[rowIdx * COLUMNS + idx]?.classList.toggle("selected")
                        }

                        if (multipleSelectedCells === true) setMultipleSelectedCells([{ x: idx, y: rowIdx, sign: currFoundSignObject }]);
                        else if (multipleSelectedCells?.length) setMultipleSelectedCells((curr : Array<{ x: number, y: number, sign: Object }>) => {
                            const sumOfPrevX = curr.reduce((acc, currValue) => acc + currValue.x, 0);
                            const sumOfPrevY = curr.reduce((acc, currValue) => acc + currValue.y, 0);
                            return [...curr, { x: idx - sumOfPrevX, y: rowIdx - sumOfPrevY, sign: currFoundSignObject }];
                        });
                       
                  
                    }}
                    tabIndex={0}
                    onKeyDown={(e : any) => {
                        // alert(`${rowIdx} ${idx}`);
                        if (e.target.tagName.toLowerCase() === "h2") return;
                        e.preventDefault();
                        // if (!isSelectedCell(idx, rowIdx)) return;

                        const selectedCells = document.querySelectorAll(".cell.selected");
                        console.log(selectedCells);
                        if (selectedCells.length > 1 && !copiedElement && !cuttedElement && !multipleSelectedCells) return;

                        const direction = e.key === "ArrowDown" ? "down" : e.key === "ArrowUp" ? "up" : e.key === "ArrowLeft" ? "left" : e.key === "ArrowRight" ? "right" : "";
                        if (direction === "") return;
                        if (direction === "up" && rowIdx - 1 < 0) return;
                        if (direction === "down" && rowIdx + 1 >= ROWS) return;
                        if (direction === "left" && idx - 1 < 0) return;
                        if (direction === "right" && idx + 1 >= COLUMNS) return;
                
                        if (!copiedElement && !cuttedElement && !multipleSelectedCells) document.querySelectorAll(".cell").forEach((cell: any) => cell.classList.remove("selected"));
                        if (isImageLocked) return;
                        if (!copiedElement && !cuttedElement && !multipleSelectedCells) changeSelectedCell(foundSignObject?.x + (direction === "left" ? -1 : direction === "right" ? 1 : 0), foundSignObject?.y + (direction === "up" ? -1 : direction === "down" ? 1 : 0), page);

                        if (currentFoundSign) {
                            if (!copiedElement && !cuttedElement && !multipleSelectedCells) changeIsSelectedSign(foundSignObject?.x + (direction === "left" ? -1 : direction === "right" ? 1 : 0), foundSignObject?.y + (direction === "up" ? -1 : direction === "down" ? 1 : 0), page);
                            cellRef.current[(foundSignObject?.y + (direction === "up" ? -1 : direction === "down" ? 1 : 0)) * COLUMNS + (foundSignObject?.x + (direction === "left" ? -1 : direction === "right" ? 1 : 0))]?.classList.toggle("selected");
                        }
                        else {
                            console.log("a", rowIdx, idx);
                            
                            if (!copiedElement && !cuttedElement && !multipleSelectedCells) {
                                removeSelectedSign();
                                changeSelectedCell(idx + (direction === "left" ? -1 : direction === "right" ? 1 : 0), rowIdx + (direction === "up" ? -1 : direction === "down" ? 1 : 0), page);
                            }
                            
                            // cellRef.current[rowIdx * COLUMNS + idx]?.classList.toggle("selected");
                            // document.querySelectorAll("cell").forEach((cell) => {
                            //     if (cell.getAttribute("row") === rowIdx.toString() && cell.getAttribute("column") === idx.toString()) cell.classList.toggle("selected")
                            // });

                            cellRef.current[(rowIdx + (direction === "up" ? -1 : direction === "down" ? 1 : 0)) * COLUMNS + (idx + (direction === "left" ? -1 : direction === "right" ? 1 : 0))]?.classList.toggle("selected");
                        }

                        const newX = idx + (direction === "left" ? -1 : direction === "right" ? 1 : 0);
                        const newY = rowIdx + (direction === "up" ? -1 : direction === "down" ? 1 : 0);
                        const newFoundSignObject = findSignObject(newX, newY);
                        
                        if (multipleSelectedCells === true) setMultipleSelectedCells([{ x: newX, y: newY, sign: newFoundSignObject }]);
                        else if (multipleSelectedCells?.length) setMultipleSelectedCells((curr : Array<{ x: number, y: number, sign: Object }>) => {
                            const sumOfPrevX = curr.reduce((acc, currValue) => acc + currValue.x, 0);
                            const sumOfPrevY = curr.reduce((acc, currValue) => acc + currValue.y, 0);
                            return [...curr, { x: newX - sumOfPrevX, y: newY - sumOfPrevY, sign: newFoundSignObject }];
                        });

                        console.log(currentFoundSign, isSelectedCell(foundSignObject?.x + (direction === "left" ? -1 : direction === "right" ? 1 : 0), foundSignObject?.y + (direction === "up" ? -1 : direction === "down" ? 1 : 0)));

                        if (!copiedElement && !cuttedElement && !multipleSelectedCells) {
                            cellRef.current.forEach((cell : any, cellIdx : any) => cellIdx !== (rowIdx + (direction === "up" ? -1 : direction === "down" ? 1 : 0)) * COLUMNS + (idx + (direction === "left" ? -1 : direction === "right" ? 1 : 0)) ? cell?.classList.remove("selected") : null);
                        }

                        let target = e.target;

                        while (!target?.classList?.contains("cell")) {
                            target = target.parentElement;
                        }

                        target.blur();
                        console.log(cellRef.current[(rowIdx + (direction === "up" ? -1 : direction === "down" ? 1 : 0)) * COLUMNS + (idx + (direction === "left" ? -1 : direction === "right" ? 1 : 0))]);
                        cellRef.current[(rowIdx + (direction === "up" ? -1 : direction === "down" ? 1 : 0)) * COLUMNS + (idx + (direction === "left" ? -1 : direction === "right" ? 1 : 0))].focus();
                    
                    }}
                >
                    {currentFoundSign}
                </div> );
    
};

export default memo(GridElement);