import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Grid from "../../Components/Grid/Grid";
import "./Project.css";
import ZenLogo from "../../imgs/ZenLogo.svg";
import Processing from "../../Components/Processing/Processing";
// console.log = () => {};

const Project = ({ data, useStore } : { data: any, useStore: any }) => {

   
    const navigate = useNavigate();
    const addSign = useStore((state : any) => state.addSign);
    const printScheme = useStore((state : any) => state.printScheme);
    const [finished, setFinished] = useState<boolean>(false);
    const reset = useStore((state: any) => state.reset);
    const [pages, setPages] = useState(new Array(1).fill(0));
    const removeSign = useStore((state: any) => state.removeSign);
    const { projectId, currentPageParam } = useParams();

    const [currentPage, setCurrentPage] = useState(currentPageParam);
    const [startPrinting, setStartPrinting] = useState<boolean>(false);
    const [pdf, setPDF] = useState<any>();
    
    const location = useLocation();
    console.log(location.state);
    const [cookies, setCookie] = useCookies();
    const pageDataRef = useRef<any>();
    const isGrid = useStore((state: any) => state.isGrid);
    const [mainPage, setMainPage] = useState(1);

    const changeElements = useStore((state: any) => state.changeElements);
  
    useEffect(() => {
      if (!signs?.length) return;
      
      if (signs.every((sign : any) => sign?.name == null)) return;
      changeElements(signs.map((sign : any) => {
        if (sign?.name == null) return sign;
        return {...sign, name : null, names: [{ name : sign.name }]};
      }));
    }, []);

    useEffect(() => {
        
    }, []);

    const loadingPhaseTime = useMemo(() => new Date().getTime(), []);

    if (!currentPage) navigate(-1);


    const changeGrid = useStore((state: any) => state.changeGrid);

    const projectData = data.userData.projects.find((project : { projectId: string }) => project.projectId === projectId);
    const isGridActivated = useStore((state: any) => state.isGrid);
    const changeGridActivated = useStore((state: any) => state.changeGridActivated);
    const changeSigns = useStore(((state: any) => state.changeElements));

    const signs = useStore((state: any) => state.signs);
    const currAddPage = useMemo(() => window?.history?.state?.addPage, []);
    const removePageState = useMemo(() => window?.history?.state?.removePage, []);
    const reseted = location?.state?.reseted;

    // alert(`${currAddPage} ${reseted}`);


    useEffect(() => {
        console.log(signs);

        // if (currAddPage) alert("added");
        // if (removePageState) alert("removed");
        
        if (signs?.length) {
            return;
        }
    
        reset();

    
        if (!projectData) navigate(-1);
       

        // if (currAddPage) alert("AA");
    
        let currentSigns = currAddPage ? JSON.parse(projectData.signs).map((sign : { page: number }) => {
            if (sign.page < Number(currentPage || 0) + 1) return sign;
            return {...sign, page: sign.page + 1};
        }) : removePageState ? JSON.parse(projectData.signs).map((sign : { page: number }) => {
            if (sign.page < Number(currentPage || 0) + 2) return sign;
            if (sign.page === Number(currentPage || 0) + 2) return null;
            return {...sign, page: sign.page - 1};
        }) : JSON.parse(projectData.signs);

        if (removePageState) {
            currentSigns = currentSigns.filter((elem : any) => elem !== null);
        }
        
        console.log(currentSigns);

        const COLUMNS = projectData.format === "A4" ? 28 : projectData === "A3" ? 40 : 28;

    
        changeSigns(currentSigns);


        let maxPage = 1;
        currentSigns.forEach((sign : { x: number, y: number, page: number, flip:number, name: string, textName: string, textAlignment: string, fontSize: number }) => {
            if (sign.page > maxPage) maxPage = sign.page;
        });

        if (Number(currentPage || 0) + 1 > maxPage) maxPage = Number(currentPage || 0) + 1;

        console.log(window.history.state.addPage, window.history.state.removePage);
        
        let timeout : any;

        if (!startPrinting) setPages(Array.from(Array(maxPage).keys()));

        if (new Date().getTime() - loadingPhaseTime >= 450 || window.history.state.skipDelay)  setFinished(true);
        else timeout = setTimeout(() => {
            setFinished(true);
        }, 450 - (new Date().getTime() - loadingPhaseTime) );

        return () => {
            clearTimeout(timeout);
        };

    }, [reset, currentPage, startPrinting]);

    useEffect(() => {
        if (!pages?.length) return;
        if (Number(currentPage) < 0) navigate(-1);
       

    }, [pages]);



    useEffect(() => {
        console.log(signs);
    }, [signs]);

    useEffect(() => {
        if (!finished || !reseted) return;
        // navigate(`/project/${projectId}/${Number(currentPage || 0)}`, { state: {...location?.state, reseted: false} });
    }, [finished]);

    useEffect(() => {
        if (!finished) return;

        window.history.replaceState({...window.history.state, addPage: false, reseted: false, removePage: false }, "");


        // if (currAddPage && !reseted) {
        //     navigate(`/project/${projectId}/${Number(currentPage || 0) + 1}`, { state: {...location.state, reseted: true} });
        // }

        // if (removePageState && Number(currentPage || 0) + 1 >= pages.length && Number(currentPage || 0) > 0) {
            
        //     window.location.replace(`/project/${projectId}/${Number(currentPage || 0) - 1}`);
        // }

        // if (currAddPage && reseted) {
        //     window.location.reload();
        //     // window.location.replace(`/project/${projectId}/${Number(currentPage || 0) + 1}`);
        // }
    }, [finished]);

    // const currAddPage = useMemo(() => window.history.addPage, []);

   
    // useEffect(() => {
    //     if (window.history.state.fullScreen) {

    //     }
    // }, []);
    

    const removePage = (idx : number) => {
        window.history.replaceState({
            ...window.history?.state,
            removePage: true,
            reseted: false,
            addPage: false
        }, "");
        window.location.reload();
    };

    const printSchemeFunctionality = async (final = false) => {
        if (!pdf) return;
        const pageContainer : any = document.querySelector(".page-container");
        pageContainer.style.borderWidth = '0px';
        const element: any = document.querySelector(".menus");
        if (element) element.style.display = 'none';
        const gridActivated = isGridActivated;
        changeGridActivated(false);
        
        pageContainer.querySelectorAll(".cell").forEach((cell: any) => {
            cell.classList.remove("selected");
        });

        console.log(pageContainer.querySelectorAll(".cell"));

        const pageName = pageContainer.querySelector(".project-name");
        const pageNumber = pageContainer.querySelector(".page-number");

        const currentVisibilityContainer : any = document.querySelector(".current-visibility-container");

        currentVisibilityContainer.style.display = 'none';


        if (pageName && pageNumber) {
            pageName.style.display = 'none';
            pageNumber.style.display = 'flex';
        }
                
        const canvas = await html2canvas(pageContainer);
        const imgData = canvas.toDataURL('image/png');
        
        pdf.addImage(imgData, "png", 0, 0, projectData.format === "A4" ? 297 : 420, projectData.format === "A4" ? 210 : 297);
        if (Number(currentPage || 0) + 1 < Number(pages.length)) pdf.addPage();
        if (pageContainer) pageContainer.style.borderWidth = '5mm';
        if (element) element.style.display = 'initial';
        changeGridActivated(gridActivated);
        
        if (pageName && pageNumber) {
            pageName.style.display = 'initial';
            pageNumber.style.display = 'none';
        }

        if (final) {
            pdf.save(`${projectData.projectName}-${projectData.format}`);
            setStartPrinting(false);
            currentVisibilityContainer.style.display = 'flex';
            setCurrentPage(currentPageParam);
        }
        else {
            setPDF(pdf);
            setCurrentPage(curr => (Number(curr || 0) + 1).toString());
        }


    };



    useEffect(() => {
        if (!startPrinting) return;

        printSchemeFunctionality(Number(currentPage || 0) + 1 === pages.length);
    
        
        
    }, [startPrinting, currentPage, pages]);

    const printInitialPage = useCallback(async () => {
        
        const pdf1 = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: [projectData.format === "A4" ? 297 : 420, projectData.format === "A4" ? 210 : 297]
        });


        if (pageDataRef.current) {

            const currentVisibilityContainer : any = document.querySelector(".current-visibility-container");
            currentVisibilityContainer.style.display = 'none';

            pageDataRef.current.style.display = 'flex';
            const canvas = await html2canvas(pageDataRef.current);
            const imgData = canvas.toDataURL('image/png');
            
            pdf1.addImage(imgData, "png", 0, 0, projectData.format === "A4" ? 297 : 420, projectData.format === "A4" ? 210 : 297); 
            pdf1.addPage();

            pageDataRef.current.style.display = 'none';

            setCurrentPage("0");
            setPDF(pdf1);
            setStartPrinting(true);
        }

    }, [pageDataRef]);

    return projectId && projectData && finished ? (
        <div className="pages-container">    

            <div className="page-data" style={{ display: 'none' }} ref={pageDataRef}>
                <div className="tabel">
                    <h2 style={{ textAlign: "center", marginBlock: 16 }}>{cookies?.language === "en" ? "Project data" : "Date proiect"}</h2>
                    <div className="row">
                        <div className="label">
                            <p>{cookies?.langauge === "en" ? "Project Name" : "Nume Proiect"}</p>
                        </div>
                        <div className="label">
                            <p>{projectData.projectName || ""}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">{cookies?.language === "en" ? "Designer" : "Proiectant"}</div>
                        <div className="label two-sides">
                            <p>{projectData.designer || ""}</p>
                            <p></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">{cookies?.language === "en" ? "Beneficiary" : "Beneficiar"}</div>
                        <div className="label">{projectData.beneficiary || ""}</div>
                    </div>
                    <div className="row">
                        <div className="label">{cookies?.language === "en" ? "Designer Company" : "Firma Proiectant"}</div>
                        <div className="label">{projectData.designerCompany}</div>
                    </div>
                    <div className="row">
                        <div className="label">{cookies?.language === "en" ? "Verifier I" : "Verificator I"}</div>
                        <div className="label two-sides">
                            <p>{projectData.verifierI}</p>
                            <p></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">{cookies?.language === "en" ? "Verifier II" : "Verificator II"}</div>
                        <div className="label two-sides">
                            <p>{projectData.verifierII}</p>
                            <p></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">{cookies?.language === "en" ? "Verifier III" : "Verificator III"}</div>
                        <div className="label two-sides">
                            <p>{projectData?.verifierIII || "-"}</p>
                            <p></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">{cookies?.language === "en" ? "Date" : "Data"}</div>
                        <div className="label">{projectData?.date || ""}</div>
                    </div>
                    <div className="row">
                        <div className="label">{cookies?.language === "en" ? "Project Id" : "Id Proiect"}</div>
                        <div className="label">{projectData?.id || ""}</div>
                    </div>
                    <div className="row">
                        <div className="label">{cookies?.language === "en" ? "No. pages" : "Nr. pagini"}</div>
                        <div className="label">{pages.length}</div>
                    </div>
                </div>

                <div className="created-by">
                    <h2>{cookies?.language === "en" ? "Created by ZenVolt" : "Creat cu ZenVolt"}</h2>
                    <img className="logo" src={ZenLogo} />
                </div>
            </div>

           
            <Grid teacherVar={true} printSchemes={printInitialPage} removePage={() => removePage(Number(currentPage) || 0)} key={(Number(currentPage) || 0) + 1} debugSchemeNumber={-1} useStore={useStore} totalPages={pages.length} page={Number(currentPage || 0) + 1} data={data} projectId={projectId || ""} projectName={projectData.projectName} category="0" format={projectData.format} /> 
             
            
            {/* <div className="new-page">
                <div className="label" onClick={() => {
                    
                    // setPages(curr => [...curr,  curr.at(-1) + 1]);
                    window.history.replaceState({...window.history.state,
                        addPage: true
                    }, "");
                    window.location.reload();
                }}>
                    <FontAwesomeIcon className="add-icon" icon={faPlus} />
                    <h2>{cookies?.language === "en" ? "Add page" : "Adauga pagina"}</h2>
                </div>
            </div> */}
        </div>
    ) : <Processing />;
   
};

export default Project;