import { faAngleLeft, faEdit, faFileLines, faPencil, faPlus, faTrashCan, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./Projects.css";
import "./Project.css";
import Header from "../../Components/Header/Header";
import DebugSessions from "../DebugSessions/DebugSessions";

const Projects = ({ data, socket } : { data: any, socket: any }) => {
    console.log(data);
    

    const navigate = useNavigate();
    const location = useLocation();
    const { module } = useParams();
    const [cookies, setCookie] = useCookies();
    const [category, setCategory] = useState(module === "edit" ? "projects" : module === "functional" ? "functional-scheme" : module === "debugging" ? "scheme-debugging" : (location?.state?.page || "projects"));

    const [debugViewOption, setDebugViewOption] = useState(location?.state?.debugViewOption || (data.userData.type === "teacher" ? 0 : 1));
    
    useEffect(() => {
        document.body.classList.remove("grid-body");
        document.querySelector("html")?.classList.remove("grid-body");

    }, [location]);

    useLayoutEffect(() => {
        if (category === location?.state?.page) return;
        navigate(`/low-tension/${module}`, { state: { page: category } });
    }, [category]);

    useLayoutEffect(() => {
        if (debugViewOption === location?.state?.debugViewOption) return;
        navigate(`/low-tension/${module}`, { state: { page: category, debugViewOption } });
    }, [debugViewOption]);

    const removeProject = async (projectId : string, e : any) => {
        
        const options = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        };

        try {
            const request = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/project/remove-project/${data.userData.userId}/${projectId}`, options);
            const response = await request.json();
            if (response.success) window.location.reload();
            else console.log(response);
        } catch (e) {
            console.log(e);
        }
    };
    

    return (
        <div className="content project-content">

            <Header data={data} socket={socket} />

            <div className="name-container">
                <div className="name">

                    <FontAwesomeIcon icon={faUser} className="user-icon" />
                    <p>{data.userData.username} {data.userData.type === "teacher" ? (cookies?.language === "en" ? "(Teacher)" : "(Profesor)") : (cookies?.language === "en" ? "(Student)" : "(Elev)")}</p>
                </div>
                {/* <div className="line" /> */}
            </div>
   
            {/* <div className="switch-options squared">
                <div className={`switch-option ${category === "projects" ? "active" : ""}`} onClick={() => setCategory("projects")}><p>{cookies?.langauge === "en" ? "Edit" : "Editare"}</p></div>
                <div className={`switch-option ${category === "functional-scheme" ? "active" : ""}`} onClick={() => setCategory("functional-scheme")}><p>{cookies?.language === "en" ? "Functional" : "Functionare"}</p></div>
                <div className={`switch-option ${category === "scheme-debugging" ? "active" : ""}`} onClick={() => setCategory("scheme-debugging")}><p>{cookies?.language === "en" ? "Debug" : "Depanare"}</p></div> 
            </div>  */}

            <div className="go-back" onClick={() => navigate(`/categories`)}>
                <FontAwesomeIcon icon={faAngleLeft} className="back-icon" />
            </div>

            <div className="module-title-container">
                <h2 className="title-module">{cookies?.langauge === "en" ? (module === "edit" ? "Edit Module" : module === "functional" ? "Functional Module" : module === "debugging" ? "Debugging Module" : "") : (module === "edit" ? "Modul Editare" : module === "functional" ? "Modul Functionare" : module === "debugging" ? "Modul Depanare" : "")}</h2>
            </div>

            {category === "scheme-debugging" ? (
                <div className="switch-options">
                    {data.userData?.type === "teacher" ? <div className={`switch-option ${debugViewOption === 0 ? "active" : ""}`} onClick={() => setDebugViewOption(0)}>
                        <p>{cookies?.language === "en" ? "Create session" : "Creeaza sesiune"}</p>
                    </div> : null}
                    <div className={`switch-option ${debugViewOption === 1 ? "active" : ""}`} onClick={() => setDebugViewOption(1)}>
                        <p>{cookies?.language === "en" ? "Ongoing Sessions " : "Sesiuni in derulare"}</p>
                    </div>
                    <div className={`switch-option ${debugViewOption === 2 ? "active" : ""}`} onClick={() => setDebugViewOption(2)}>
                        <p>{cookies?.language === "en" ? "Finished Sessions" : "Sesiuni Finalizate"}</p>
                    </div>
                </div>
            ) : null}

            {category === "projects" ? (
                <>
                    {data.userData.projects?.length ?
                        <div className="projects-container">
                            {data.userData.projects.map((project : { projectName: string, format: string, projectId: string }, idx : number) => (
                                <div className="project-gradient" key={idx} onClick={(e : any) => e.target.tagName !== "svg" && e.target.tagName !== "path" ? window.location.replace(`/project/${project.projectId}/0`) : null}>
                                    <div className={`project`}>
                                        <h2>{project.projectName}  <div className="edit-container" onClick={() => navigate(`/edit-project/${project.projectId}`, { state: { project: project } })}>
                                            <FontAwesomeIcon icon={faPencil} className="edit-icon" />
                                        </div> </h2>

                                        <div className="format">
                                            <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                            <p>{project.format}</p>
                                        </div>

                                        <FontAwesomeIcon icon={faTrashCan} className="delete-icon" onClick={async (e : any) => await removeProject(project.projectId, e)} />
                                       
                                    </div>
                                </div>
                            ))}
                        </div>
                    : 
                        <div className="empty-container">
                            <h2>Nu au fost create scheme.</h2>
                        </div>
                    }
                </>
            ) : category === "functional-scheme" ? (
                <div className="projects-container functional">
                        <div className="project-gradient" onClick={(e : any) => navigate(`/predefined-scheme/1`)}>
                            <div className="project functional">
                                <h2>{cookies.language === "en" ? "Turning on the light bulb" : "Aprinderea bec"}</h2>

                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div>

                            </div>

                        </div>

                        <div className="project-gradient" onClick={(e : any) => navigate(`/predefined-scheme/2`)}>
                            <div className="project functional">
                                <h2>{cookies.language === "en" ? "One-way starting of the three-phase asynchronous motor" : "Pornirea într-un singur sens a motorului asincron trifazat"}</h2>

                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div>


                            </div>
                        </div>

                        <div className="project-gradient" onClick={(e : any) => navigate(`/predefined-scheme/3`)}>
                            <div className="project functional">
                                <h2>{cookies.language === "en" ? "Current direction inverter" : "Inversorul de sens"}</h2>

                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div>


                            </div>
                        </div>

                        <div className="project-gradient" onClick={(e : any) => navigate(`/predefined-scheme/4`)}>
                            <div className="project functional">
                                <h2>{cookies.language === "en" ? "Star-Triangle starting" : "Pornirea stea-triunghi"}</h2>

                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A3</p>
                                </div>


                            </div>
                        </div>
                   
                </div>
            ) : debugViewOption === 0 ? (
                <div className="projects-container">
                        <div className="project-gradient" onClick={(e : any) => navigate(`/predefined-scheme-debug/1`)}>
                            <div className="project debugging">
                                <h2>{cookies.language === "en" ? "Basic circuit" : "Circuit simplu"}</h2>

                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div>


                            </div>

                           
                        </div>

                        <div className="project-gradient" onClick={(e : any) => navigate(`/predefined-scheme-debug/2`)}>
                            <div className="project debugging">
                                <h2>{cookies.language === "en" ? "One-way starting of the three-phase asynchronous motor" : "Pornirea într-un singur sens a motorului asincron trifazat"}</h2>

                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div>


                            </div>
                        </div>

                        <div className="project-gradient" onClick={(e : any) => navigate(`/predefined-scheme-debug/3`)}>
                            <div className="project debugging">
                                <h2>{cookies.language === "en" ? "Current direction inverter" : "Inversorul de sens"}</h2>

                                <div className="format">
                                    <FontAwesomeIcon icon={faFileLines} className="format-icon" />
                                    <p>A4</p>
                                </div>


                            </div>
                        </div>

                   
                </div>
            ) : debugViewOption === 1 ? (
                <DebugSessions data={data} socket={socket} finished={false} key={0} />
            ) : debugViewOption === 2 ? (
                <DebugSessions data={data} socket={socket} finished={true} key={1} />
            ) : null} 

            <div className={`add-button ${category === "projects" ? "show" : ""}`} onClick={() => category === "projects" ? navigate("/create-project") : null}>
                <FontAwesomeIcon icon={faPlus} className="add-icon" />
                <p>{cookies?.language === "en" ? "Add project" : "Adauga Proiect"}</p>
            </div>

            {/* {data.userData.admin ? <div className={`add-button show left-side`} onClick={() => navigate("/create-account")}>
                <FontAwesomeIcon icon={faPlus} className="add-icon" />
                <p>{cookies?.language === "en" ? "Create account" : "Creeaza cont"}</p>
            </div> : null} */}


        </div>
    );
};

export default Projects;